const ignoredPermitData = [
  "Accuracy Score",
  "Accuracy Type",
  "Area",
  "area",
  "Bldg Sqft",
  "City",
  "Contractor",
  "County",
  "Country",
  "Estimated Construction Cost",
  "Issue Date",
  "Latitude",
  "Longitude",
  "Number",
  "Site Location",
  "Source",
  "State",
  "Street",
  "Unit Type",
  "Unit Number",
  "Value",
  "Zip",
  "applicant",
  "delcared_valuation",
  "total_fees",
  "status",
  "owner",
  "occupancytype",
  "sq_feet",
  "property_id",
  "parcel_id",
  "comments",
  "nothing",
  "",
  "zero",
  "bbox",
];

export default ignoredPermitData;
